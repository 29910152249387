<template>
  <v-card flat min-height="100vh" color="transparent">
    <v-card-title class="xflex xflex-row xjustify-center xitems-center xw-full">
      <Filters :value="selectedDate" @change="navigate" type="daily"></Filters>
    </v-card-title>
    <v-card-text>
      <TeamRecordTable
        :records="records"
        :fetching="fetching"
        empty-text="No records found for the selected date"
        @navigate="goToUserRecord"
      ></TeamRecordTable>
    </v-card-text>
    <!-- <pre>{{ records }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import TeamRecordTable from "../components/TeamRecordTable.vue";
export default {
  components: { Filters, TeamRecordTable },
  data() {
    return {
      fetching: false,
      records: [],
      selectedDate: moment().format("YYYY-MM-DD"),
    };
  },
  watch: {
    "$route.params.date": {
      handler: function (val) {
        this.selectedDate = val;
        val && this.fetchSummary(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.$event.$on("refetch-ptos", () => this.fetchSummary(this.selectedDate));
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-team-record-daily",
          params: { date: val },
          query: { redirect_uri: this.$route.fullPath },
        })
        .catch(() => {});
    },
    goToUserRecord(item) {
      this.$router
        .push({
          name: "app-user-record-daily",
          params: {
            id: item.id,
            date: this.selectedDate,
          },
        })
        .catch(() => {});
    },
    fetchSummary(date) {
      this.fetching = true;
      this.$axios
        .get(`api/team-records/daily/${date}?include_status=1`)
        .then(({ data }) => {
          this.records = data;
          this.$event.$emit("team-record-count", data.length);
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
